import React from 'react';

const DeployPage = ({ className }) => {
  const [res, $res] = React.useState('');
  React.useEffect(() => {
    setTimeout(async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const secret = params.get('secret');
        if(secret) {
          let response = await fetch(
            `https://${secret}.execute-api.us-east-1.amazonaws.com/stage/build/blog`,
            {
                method: 'POST',
                body: ''
            }
          );
          const result = await response.json();
          console.log();
          $res(result);
        }
      } catch(err) {
          console.log('done');
      }
    });
  }, []);
  return (<div>
    <div>Deploy Page</div>
    <div>{res}</div>
  </div>)
}


export default DeployPage;
